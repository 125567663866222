<template>
  <a-dropdown :class="$style.dropdown" style="border-left: 1px solid #d1cad8">
    <div :class="$style.welcome">
      <div style="margin-right: 5px">
        <b v-if="workspace">{{ workspace.name }}</b>
        <div>
          <CommonPlanBadge :plan="plan"/>
        </div>
      </div>
      <a-avatar :icon="user.name ? null : h(UserOutlined)" style="background: #d61a7f">{{ initials }}</a-avatar>
    </div>

    <template #overlay>
      <a-menu :class="$style.menu">
        <template v-if="user.id">
          <a-menu-item v-if="!workspace">
            <nuxt-link
                :to="'/account/workspaces'"
                :class="$style.link"
            >
              <PlusOutlined/>
              {{ $t('components.header.UserActions.addWorkspace') }}
            </nuxt-link>
          </a-menu-item>

          <a-menu-item v-for="(ws, id) in workspaces" :key="ws.id">
            <div :class="$style.item">
              <nuxt-link
                  :to="`/w/${ws.slug}/`"
                  :class="$style.link"
                  no-prefetch
              >
                <AppstoreOutlined/>
                {{ ws.name }}
              </nuxt-link>
              <nuxt-link :to="`/w/${ws.slug}/manage`" style="display: flex">
                <a-divider type="vertical"/>
                <SettingOutlined/>
              </nuxt-link>
            </div>
          </a-menu-item>

          <a-menu-divider/>

          <a-menu-item key="forum" title="">
            <nuxt-link href="https://forum.rootle.de" target="_blank">
              <QuestionCircleOutlined/>
              Forum & Support
            </nuxt-link>
          </a-menu-item>

          <a-menu-item key="billing" title="">
            <nuxt-link to="/account/billing" :class="$style.link">
              <CrownOutlined/>
              Upgrade
            </nuxt-link>
          </a-menu-item>

          <a-menu-item>
            <nuxt-link to="/account" :class="$style.link">
              <UserOutlined/>
              {{ $t('components.header.UserActions.settings') }}
            </nuxt-link>
          </a-menu-item>
        </template>

        <a-menu-item v-else>
          <nuxt-link to="/register" :class="$style.link">
            <UserOutlined/>
            {{ $t('components.header.UserActions.createAccount') }}
          </nuxt-link>
        </a-menu-item>

        <a-menu-item>
          <ViewsHeaderLogoutButton :class="$style.link"/>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script lang="ts" setup>
import {UserOutlined} from '#components';

const rootStore = useRootStore();
const {user, workspaces, workspace, plan} = storeToRefs(rootStore);

const initials = computed(() => {
  if (user.value?.name) {
    const fl = user.value.name.split(' ').map(n => n.trim()).filter(n => n);
    return fl.length > 1 ? fl[0][0] + fl[1][0] : user.value.name[0];
  }

  return 'T'; // TRIAL
});

</script>

<style lang="scss" module>
.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.welcome {
  display: flex;
  line-height: 1.2;
  align-items: center;
  justify-content: space-between;
}

.dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 50px;
  padding: 0 10px;
  flex: 1 0 140px;
  max-width: 210px;
  width: 100%;
  font-size: 16px;
}

.item {
  display: flex;
  justify-content: space-between;
}

.menu {
  width: 320px;
  margin-top: 12px;
}

.link {
  color: black;
  display: block;
  width: 100%;
  height: 100%;
}

.usermenu {
  background-color: green;
}
</style>