<template>
  <MainApp>
    <a-layout :class="$style.layout">
      <ViewsHeader
          :collapsed="collapsed"
          @click-trigger="visible = true"
      />
      <a-layout :has-sider="true">
        <Sider
            v-if="workspace"
            :visible="visible"
            :collapsed="collapsed"
            @close="visible = $event"
            @visible="visible = $event"
            @collapse="collapsed = $event"
        />

        <a-layout-content :class="$style.content" id="main">
          <slot/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </MainApp>
</template>

<script lang="ts" setup>
//import {theme} from 'ant-design-vue';
// :theme="{ algorithm: theme.darkAlgorithm}"
useHead(defaultHead());

const collapsed = ref(true);
const visible = ref(false);

const rootStore = useRootStore();
const workspace = rootStore.workspace;

</script>

<style lang="scss" module>
.layout {
  background: $bg;
  height: 100vh;
}

.content {
  height: auto;
  overflow: auto;

  @media all and (max-width: $md) {
    padding: 0;
  }
}
</style>
