<template>
  <div :class="$style.header">
    <MenuOutlined
        :class="$style.trigger"
        type="menu"
        @click="emit('click-trigger')"/>
    <div :class="$style.brand">
      <NuxtLink to="/">
        <CommonLogo height="25px"/>
      </NuxtLink>
    </div>
    <template v-if="user">
      <div v-if="!user.id" :class="$style.createAccount">
        <a-button
            type="primary" @click="navigateTo('/auth/register')"
            style="margin-left: auto"
        >Konto erstellen
        </a-button>
      </div>
      <ViewsHeaderUserActions/>
    </template>
  </div>
</template>
<script lang="ts" setup>
defineProps({
  collapsed: Boolean
});

const emit = defineEmits(['click-trigger']);
const rootStore = useRootStore();
const {user} = storeToRefs(rootStore);

</script>
<style lang="scss" module>
.header {
  display: flex;
  padding: 10px;
  align-items: center;
  min-height: 68px;
  height: 68px;
  color: #232323;
  background: $bg;
  position: relative;
  z-index: 100;

  @media all and (max-width: $md) {
    margin: 0;
    border-radius: 0;

    .brand {
      display: none;
    }
  }
}

.createAccount {
  display: flex;
  align-items: center;
  width: 100%;

  @media all and (max-width: $md) {
    display: none;
  }
}

.actions {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.trigger {
  font-size: 18px;
  line-height: 56px;
  padding: 0 14px;
  cursor: pointer;
  transition: color .3s;
  color: #232323;
  display: none;

  @media all and (max-width: $md) {
    display: block;
  }
}

.trigger:hover {
  color: #232323;
}

.brand {
  padding-left: 10px;
  color: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: -1px;
  font-family: $font-family;
}
</style>
