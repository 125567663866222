<template>
  <a-dropdown :trigger="['click']" :class="$style.dropdown" placement="bottomLeft">
    <span v-if="!hasChannels" @click="navigateTo('/w/' + workspace.slug + '/manage/channels')">
      <PlusOutlined/>
      <span>{{ $t('components.header.UserActions.addChannel') }}</span>
    </span>
    <span v-else :class="$style.channelLink">
      <span v-if="channel">
        <a-avatar v-if="store" :src="store.logo" :class="$style.avatar"/>
        <a-avatar v-else style="color: #f56a00; background: #fde3cf"
                  :class="$style.avatar">{{ channel.type }}</a-avatar>
        <span>{{ channel.name }}</span>
      </span>
      <span v-else> Meine Kanäle</span>
      <DownOutlined v-if="hasChannels && !isTrialUser"/>
    </span>

    <template #overlay>
      <a-menu v-if="hasChannels && !trial">
        <a-menu-item v-for="(channel) in channels" :key="channel.slug">
          <nuxt-link :to="{query: {channel: channel.slug}}" no-prefetch :class="$style.link">{{
              channel.name
            }}
          </nuxt-link>
        </a-menu-item>
        <template v-if="workspace.is_owner">
          <a-menu-divider/>
          <a-menu-item @click="navigateTo('/w/' + workspace.slug + '/manage/channels')">
            <PlusOutlined/>
            <span>{{ $t('components.header.UserActions.addChannel') }}</span>
          </a-menu-item>
        </template>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script lang="ts" setup>
const rootStore = useRootStore();
const {workspace, workspaces, channels, channel, marketplace, isTrialUser} = storeToRefs(rootStore);

const hasChannels = computed(() => Object.keys(channels.value).length > 0);
const store = computed(() => marketplace.value?.data?.store ?? null);
</script>

<style lang="scss" module>
.dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 50px;
  padding: 0 10px;
  flex: 1 0 140px;
  max-width: 200px;
  width: 100%;
  font-size: 16px;
}

.channelLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  margin-right: 20px;
}

.avatar {
  margin-right: 5px;
  flex-shrink: 0;
}

.link {
  display: block;
  width: 100%;
}
</style>
