<template>
  <CommonDrawer
      :collapsed="inDrawer"
      :drawer="{
        width: 'auto',
        bodyStyle: {padding: 0, height: '100%' },
        placement: 'left',
        visible,
        closable: false // Remove Close Button
      }"
      @close="emit('close', false)"
      :wrapper="{class: $style.root}"
  >
    <template #content>
      <a-layout-sider
          :collapsible="false"
          :class="[$style.sider, visible ? $style.open : '']"
          :collapsed="collapsed"
          @collapse="emit('collapse', $event)"
          theme="light"
      >
        <a-menu
            mode="inline"
            theme="light"
            :title="selectedKeys"
            :selectedKeys="selectedKeys"
            :openKeys.sync="openKeys.list"
            @click="emit('visible', false)"
        >
          <a-menu-item key="index" title="">
            <nuxt-link :class="$style.siderLink" class="default-link" to="/">
              <IconsCustomIcon type="dashboard" :class="$style.menuItem"/>
              <span>{{ $t('sider.dashboard') }}</span>
            </nuxt-link>
          </a-menu-item>

          <a-menu-item v-if="isSuperAdmin" key="analytics" title="">
            <nuxt-link :class="$style.siderLink" class="default-link" :to="`/w/${workspace.slug}/analytics`">
              <IconsCustomIcon type="chart" :class="$style.menuItem"/>
              <span>Analytics</span>
            </nuxt-link>
          </a-menu-item>

          <a-menu-item v-if="isSuperAdmin" key="sales" title="">
            <nuxt-link :class="$style.siderLink" class="default-link" :to="`/w/${workspace.slug}/orders/sales`">
              <IconsCustomIcon type="cart" :class="$style.menuItem"/>
              <span>{{ $t('sider.orders') }}</span>
            </nuxt-link>
          </a-menu-item>

          <!--
                    <a-menu-item v-if="isAdmin" key="history" title="">
                      <nuxt-link class="default-link" :to="`/w/${workspace.slug}/orders/history`">
                        <IconsCustomIcon type="bag" :class="$style.menuItem"/>
                        <span>{{ $t('sider.purchased') }}</span>
                      </nuxt-link>
                    </a-menu-item>
          -->

          <a-menu-item key="messages" title="">
            <nuxt-link :class="$style.siderLink" class="default-link" :to="`/w/${workspace.slug}/messages`">
              <IconsCustomIcon type="comments" :class="$style.menuItem"/>
              <span>{{ $t('sider.messages') }}</span>
            </nuxt-link>
          </a-menu-item>

          <a-menu-item v-if="!isTrialUser" key="settings" title="">
            <nuxt-link :class="$style.siderLink" class="default-link" :to="`/w/${workspace.slug}/manage/settings`">
              <IconsCustomIcon type="settings" :class="$style.menuItem"/>
              <span>{{ $t('sider.settings') }}</span>
            </nuxt-link>
          </a-menu-item>


          <a-menu-item v-if="isSuperAdmin" key="admin" title="">
            <nuxt-link to="/admin" :class="$style.siderLink" class="default-link">
              <ToolFilled/>
              <span>Admin</span>
            </nuxt-link>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
    </template>
  </CommonDrawer>
</template>
<script lang="ts" setup>
defineProps({
  visible: Boolean,
  collapsed: Boolean
});


const emit = defineEmits(['close', 'visible', 'collapse']);

const openKeys = reactive({list: []});
const bp = useResponsive();
const inDrawer = bp.smaller('md');

const rootStore = useRootStore();
const {current, isSuperAdmin, isTrialUser, workspace} = storeToRefs(rootStore);
const currentPage = computed(() => current.value.page);
const rootPage = computed(() => currentPage.value.split('/').filter((p: string) => p)[0]);
const selectedKeys = computed(() => [rootPage.value]);

function setRootKey(current: string) {
  const root = current.split('/').find(p => p);

  if (root) {
    openKeys.list = [root];
  }
}

watch(currentPage, setRootKey);

setRootKey(currentPage.value);
</script>

<style lang="scss" module>
$icon: #202250;

:global(.ant-layout-sider).sider {
  height: 100%;
  background: $bg;
  z-index: 50;

  :global .ant-layout-sider-children {
    padding-top: 40px;
  }

  @media all and (max-width: $md) {
    display: none;
  }

  &.open {
    @media all and (max-width: $md) {
      display: block;
    }
  }

  :global .ant-layout-sider-trigger {
    background: transparent;
    color: $icon;
  }

  :global .ant-menu {
    background: transparent;
    height: 100%;
  }


  :global .ant-menu-vertical,
  :global .ant-menu-inline {
    > .ant-menu-item-selected {
      background: transparent;
    }

    border-color: transparent;

    > .ant-menu-item {
      margin: 0;
      width: 100%;
      height: 75px;
      display: flex;
      justify-content: center;

      > a {
        color: $icon;
        display: flex;
        align-items: center;

      }

      &::after {
        border: none;
      }

      &:active {
        background: transparent;
      }
    }

    > .ant-menu-item-selected {
      &:active {
        background-color: #e0e0e0;
      }

      .anticon {
        color: #0c4dff;
        font-size: 24px;
      }

      background-color: #ececec;
    }

  }

  :global .ant-menu-inline-collapsed {
    > .ant-menu-item-selected {
      background: transparent;
    }

    > .ant-menu-item {
      padding: 0 !important;
      display: flex;
      align-items: center;

      &:hover {
        background: transparent;
      }

      > a {
        height: 100%;
        width: 100%;
        flex-direction: column;
      }
    }
  }

  :global .ant-menu-item .anticon {
    color: $icon;
    font-size: 24px;
  }

  :global .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
    line-height: 1;
    opacity: 1;
    max-width: 80%;
    width: auto;
    font-size: 12px;
    font-weight: 400;
  }
}

.root {
  display: flex;
  height: auto;
}

.menuItem :active {
  color: blue;
}

.menuLink {
  font-weight: 700;
}

.siderLink {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-decoration: none;
}
</style>
