<template>
  <div :class="$style.actions">
    <ViewsHeaderChannelSelector v-if="workspace"/>
    <ViewsHeaderWorkspaceSelector/>
  </div>
</template>

<script lang="ts" setup>
const rootStore = useRootStore();
const {workspace} = storeToRefs(rootStore);
</script>

<style lang="scss" module>
.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.welcome {
  display: flex;
  line-height: 1.2;
  align-items: center;
  justify-content: space-between;
}

.dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 50px;
  padding: 0 10px;
  flex: 1 0 140px;
  max-width: 200px;
  width: 100%;
  font-size: 16px;
}

.item {
  display: flex;
  justify-content: space-between;
}

.menu {
  width: 320px;
  margin-top: 12px;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
}


.usermenu {
  background-color: green;
}
</style>
